@import "variables";
@import "ui/mixins";

// Core CSS
@import "ui/utilities";
@import "ui/type";
@import "ui/button";
@import "ui/tables-responsive";

// Components
@import "ui/badge";
@import "ui/blockquote";
@import "ui/box";
@import "ui/callout";
@import "ui/call-to-action";
@import "ui/card-blog";
@import "ui/card-box";
@import "ui/card-form";
@import "ui/card-icon";
@import "ui/card-image";
@import "ui/card-number";
@import "ui/card-portfolio";
@import "ui/card-product";
@import "ui/card-profile";
@import "ui/cover";
@import "ui/feature-callout";
@import "ui/forms";
@import "ui/hero";
@import "ui/hover";
@import "ui/hover-basic";
@import "ui/hover-link";
@import "ui/icon";
@import "ui/icon-social";
@import "ui/images";
@import "ui/list";
@import "ui/loader";
@import "ui/pricing-tables";
@import "ui/ribbon";
@import "ui/sash";
@import "ui/testimonials";
@import "ui/timeline";

@import url("https://fonts.googleapis.com/css?family=Lato:700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@700&display=swap');

// .brand img {
//   vertical-align: text-bottom;
// }

// @media (min-width: 1920px) {
//   .container {
//     max-width: 1440px;
//   }
// }

// $theme_gradient: transparent linear-gradient(180deg, #d45895 0%, #ef5869 100%) 0% 0% no-repeat padding-box;
$theme_gradient: #4B426B;
$theme_button_color: transparent linear-gradient(180deg, #d45895 0%, #ef5869 100%) 0% 0% no-repeat padding-box;
$new-theme_gradient:transparent linear-gradient(90deg, #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
$new-border:0.5px solid rgba($color: #694B94, $alpha: 0.3);

.brand img {
  vertical-align: top;
}

.hyperlink {
  cursor: pointer;
  color: $theme_gradient;
}
.highlighted {
  font-weight: 600;
}

h4,
h5 {
  font-family: "Lato", sans-serif;
}

.bg-info {
  background-color: #fff !important;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
}

.app-header .bg-info a,
.app-header .bg-info a .ant-badge i,
.app-sidenav .bg-info a {
  color: dimgray;
}
// notifications
.app-header-notifications .ant-tabs-nav .ant-tabs-tab {
  padding: 1rem;
  border-bottom: none;
}
.app-header-inner .list-inline-item .list-notification-icon {
  font-size: 21px;
  padding: 4px;
}
.app-header-inner .list-inline-item .ant-badge .ant-badge-count {
  font-size: 13px;
  font-weight: 300;
  padding: 0 4px;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.ant-avatar-lg {
  height: 48px;
  width: 48px;
}
.avatar-text {
  width: 119px;
  height: 30px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  color: #707070;
}
#app-content {
  background-color: #FFFFFF;
}
.sidenav-content {
  border-right: none;
}

.profile-box-detail {
  min-height: 600px;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  // border-radius: 0.375rem;
  background-color: #fff;
  & h4 {
    font-size: 16px;
  }
}
.ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
}
.profile-box-detail-help {
  min-height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: #fff;
}
.midSizedNumber {
  font-size: 24px;
}
.demo-style-steps .steps-content {
  text-align: left !important;
  padding: 5px;
}
.demo-style-steps .steps-action {
  float: right;
}
.JobItem {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 15px #00000029;
  border: 0.30000001192092896px solid rgba($color: #694B94, $alpha: 0.3);
  border-radius: 16px;
  margin-bottom: 15px;
}
.JobItem__img{
  display: flex;
  align-items: center;
  justify-content: center;
}
.JobItem img,
.article .profile-card-v2 img {
  width: 80% !important;
  padding: 20px 0;
  text-align: center;
  max-height: 120px;
  box-shadow: none;
  border-radius: 0;
}

// .blog-card__content {
//   height: 50px;
//   overflow: hidden;
//   -webkit-line-clamp: 2;
//   text-overflow: ellipsis;
// }
.ant-modal-close {
  position: absolute;
  top: 4px;
  right: 4px;
}
.ant-modal-close-x {
  // background: linear-gradient(to right, #de57e5, #8863fb);
  background-color: #FFFFFF;
  border-radius: 51%;
  border: 1px solid #4B426B;
  color: #4B426B;
  width: 28px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
  margin-left: 20px;
}
.avatar-text {
  width: 119px;
  height: 30px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  color: #707070;
}
.JobItem__JobDesc {
  text-align: left;
  height: 70px;
  font-size: 14px;
  // margin-bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  // line-height: 1.5;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
.JobItem__Bookmark--purple {
  background: rgba(128, 0, 128, 0.3);
  padding: 6px 4px;
  border-radius: 4px;
}
.formInputWrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 60%;
  background: #fff;
}
.companyLogo {
  height: 100px;
  width: 100px;
}
.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}
.search-slt {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #55595c;
  padding: 20px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}
.search-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
  background: linear-gradient(to right, #de57e5, #8863fb);
  height: 40px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  border: none;
  font-weight: 600;
}
.ant-form legend {
  width: 79%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: inherit;
  color: rgba(0, 0, 0, 0.45);
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
}
fieldset {
  width: 100%;
  display: flex;
}

@media (min-width: 992px) {
  .search-sec {
    position: relative;
    top: -114px;
    background: rgba(26, 70, 104, 0.51);
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: #1a4668;
  }
}
// @media screen and (max-width: 1360px) and (min-width: 1200px) {
//   .ant-modal {
//     // width: 70% !important;
//   }
//   .view-shortlist-company-modal,
//   .subscription-modal {
//     // width: 100% !important;
//   }
// }
// @media screen and (max-width: 1200px) and (min-width: 999px) {
//   .ant-modal {
//     // width: 65% !important;
//   }
//   // .view-shortlist-company-modal,
//   // .subscription-modal {
//   //   // width: 100% !important;
//   // }
// }
.jobdesc_table {
  max-height: 115px !important;
}
// @media screen and (max-width: 999px) and (min-width: 550px) {
//   .container {
//     width: 800px !important;
//     overflow: hidden;
//     overflow-x: scroll;
//   }
// }

// TABLE CSS
.ant-table {
  font-size: 15px;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: none;
}
.ant-table-title {
  float: left;
  padding-left: 15px;
  font-size: 20px;
  background: $theme_gradient;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
.ant-table-thead {
  background: $new-theme_gradient;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: none;
}
.ant-table-thead > tr > th {
  color: #fff;
  font-weight: 600;
  text-align: left;
  background: transparent;
  border: none;
  border-radius: 0;
}
.ant-table-thead > tr:first-child > th:last-child,
.ant-table-thead > tr:first-child > th:first-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.ant-table-thead > tr > th .ant-table-column-sorter{
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #ffffff;
  background: transparent;
  line-height: 1em;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter {
  color: #fff;
  font-size: 14px;
}
tr.ant-table-row.ant-table-row-level-0 {
  background: #fff;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: none;
}
.stats_label {
  color: #d45895;
  font-size: 16px;
  font-weight: 600;
}
.stats_count {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  color: #707070;
  font-weight: 700;
}
.JobStatsWrapper {
  background: white;
  text-align: center;
  overflow: hidden;
}
// Discussion Board
.ant-comment-content-author {
  .ant-comment-content-author-name,
  .ant-comment-content-author-time {
    color: #1d0f0f;
  }
}

.ant-tabs-bar {
  border-bottom: none;
}
.partner-tabs {
  // color: white;
  // min-height: 600px;
  .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 32px;
    margin: 0px 20px 0 0;
    color: #fff;
    background-color: rgb(212, 88, 149);
    &:hover {
      color: white;
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: white;
    background-color: rgb(239, 88, 105);
  }
}
.view-all-jobs-modal .ant-modal-body {
  max-height: 550px;
  min-height: 400px;
  overflow-y: scroll;
}

.view-shortlist-company-modal {
  // top: 10px;
//   .ant-modal-content {
//     border-radius: 0;
//     width: 90%;
//     margin: auto;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
// box-shadow: 0px 12px 15px #00000029;
// border: 0.30000001192092896px solid rgba($color: #4B426B, $alpha: 0.3);
// border-radius: 16px;
// opacity: 1;
//   }
//   .ant-modal-close {
//     margin: 4px;
//   }
  .job-table{
    box-shadow: 0px 12px 15px #00000029;
    border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
border-radius: 16px;
opacity: 1;
  }
 .job-table__title{
   background: $new-theme_gradient;
   padding-top: 5px;
   border-radius: 16px 16px 0 0 ;
   h3{
    font: normal normal 300 20px/25px Poppins;
    color: #FFFFFF;
   }
 }
 .ant-table-placeholder{
   border-radius:0 0 16px 16px ;
 }


 .job-table_btn{
  .ant-btn{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border: 0.30000001192092896px solid #694B94;
border-radius: 11px;
width: 100px;
height: 33px;
span,i{
  color: #4B426B;
}
  }
  .ant-btn > .anticon + span{
    margin-left:1px;
  }
  
}


}
.company-info-modal {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 0.375rem;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // background-color: #fff;
  // text-align: center;
  img {
    width:80px;  
    height: 80px;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 15px #00000029;
    border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);

  }
  .company-info-modal__company-name{
    font: normal normal 600 14px/20px Poppins;
color: #4B426B;
margin-top: 5px;

  }
}
.company-info-details{
  h5{
    font: normal normal 600 15px/23px Poppins;
color: #4B426B;
  }
  p{
    font: normal normal 300 11px/20px Poppins;
color: #4B426B;
  }
}
.company-job__btn{
  .ant-btn{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border: 0.30000001192092896px solid #694B94;
border-radius: 19px;
height: 40px;
span,svg{
  font: normal normal 600 14px/20px Poppins;
letter-spacing: 0px;
color: #4B426B;
}
&:focus,&:hover{
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
  }
}
 .JobStatsWrapper{
   border-radius: 16px;
  .swiper-button-next:after,.swiper-button-prev:after{
    font-size: 20px !important;
  }
  .swiper-button-next,.swiper-button-prev{
    color: #4B426B !important;
  }
  .top-button-wrapper{
    .top-button-wrapper_title{
      font: normal normal 600 15px/0px Poppins;
color: #4B426B;
    }
    .top-button-wrapper__viewall{
      font: normal normal 300 13px/20px Poppins;
color: #4B426B;
cursor: pointer;
    }
  }
  .swiper-slide{
    width: 100% !important;
  } 
 }
 .dashboard-right__container{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 15px #00000029;
  border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
  border-radius: 13px;
  opacity: 1;
  width: 100%;
  min-height: 800px;
 }
.job-list-item {
  width:95%;
  height: 200px;
  // opacity: 1;
  padding: 0 15px;
  margin: auto;
  // -webkit-transition: ease-in-out 0.5s;
  // &:hover {
  //   transform: translateY(-8px);
  // }
  // background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 8px 15px #00000029;
border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
border-radius: 16px;

  .event-title {
    text-align: center;
    height: 70px;
    // display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;   
      font: normal normal 600 14px/20px Poppins;
color: #4B426B;
    
  }
  .enroll_btn{
    background:$new-theme_gradient;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
color: #FFFFFF;
border: none;
width: 90px;
margin-bottom: 7px;
height: 25px;
  }
  .job-container-card {
    display: flex;
    justify-content: space-between;
  }
  
  .job-oppurtunities {
    font: normal normal 600 30px/20px Poppins;
color: #4B426B
  }
  .sub-text {
    font: normal normal 600 15px/20px Poppins;
    color: #4B426B
    // border-bottom: 2px solid #119da4;
  }
  .sub-text:after {
    content: "";
    width: 33px;
    display: block;
    margin-top: 10px;
    border-bottom: 2px solid #119da4;
  }
  .job-container-card__lastJob{
    h5{
      font: normal normal 600 15px/20px Poppins;
      color: #4B426B
    }
  }
  .text {
    margin-top: 15px;
  }
  .job-container-card__date,.job-container-card__location{
    color: #4B426B;
    font: normal normal 300 14px/25px Poppins;
  }
}
// ************JOB POSTING STARTS************
.post-steps .ant-steps-item-title::after {
  display: none;
}
.post-steps .ant-steps-item-container {
  outline: none;
  padding: 10px;
}
.post-steps .ant-steps-item-process .ant-steps-item-icon {
  background: #4fc752;
  border-color: transparent;
  float: right;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #cbcbcc;
  border-color: #cbcbcc;
  float: right;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff;
}
.post-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #d80075;
}
.post-steps .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0px;
  border: 1px #cbcbcc solid;
  border-radius: 1px;
}
.post-steps .ant-steps-item-active {
  border: 2px solid #d80075 !important;
}
.demo-style-steps .steps-content {
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background-color: transparent !important;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.profile-card-v2 .demo-style-steps .steps-content {
  border: none !important;
}

// ************JOB POSTING ENDS ************
//  form
.ant-form-item {
  margin-bottom: 18px;
}
.ant-radio-button-wrapper {
  // border-left: 1px solid #d9d9d9;
  // border-radius: 6px 0 0 6px;
  margin-right: 10px;
}
.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child {
  // border-left: 1px solid #d9d9d9;
  border-radius: 0;
}
.ant-form-item-label > label {
  color: #1d0f0f;
  font-size: 16px;
  font-weight: 600;
}
.ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 18px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
// .ant-form-item-control span{color: #707070; font-size: 18px;}
.ant-input,
.ant-input-number,
.ant-select-selection--single,
.ant-select-selection--multiple {
  height: 33px;
  padding: 2px 2px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.steps-action .ant-btn,
.steps-action .ant-btn .ant-btn-primary {
  width: 180px;
  height: 50px;
  font-size: 16px;
}
.ant-form-item-control span {
  font-size: 14px;
  font-weight: 600;
}
.demo-style-steps .steps-action-save-draft {
  margin-top: 24px;
}
.demo-style-steps .steps-action-save-draft {
  float: left;
}
.steps-action-save-draft .ant-btn,
.steps-action-save-draft .ant-btn .ant-btn-primary {
  width: 180px;
  height: 50px;
  font-size: 16px;
}

// carousel
.ant-carousel .slick-slide img {
  display: inline-block;
}
.ant-carousel .slick-dots-bottom {
  bottom: -28px;
  z-index: 999;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #d45895;
  opacity: 1;
}
.ant-carousel .slick-dots li button {
  color: #d45895;
  opacity: 0.5;
  background: #d45895;
}

// tags
.ant-tag-green,
.ant-tag-geekblue {
  color: #707070;
  background: transparent;
  border-color: transparent;
  font-size: 15px;
}
.ant-tag-green:before {
  background: #1fc753;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 12px;
  border-radius: 5px;
}

.ant-tag-geekblue:before {
  background: #c71f39;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 12px;
  border-radius: 5px;
}

// LineUpsTable
// .view-lineups-modal .ant-table-thead > tr > th {
//   border-bottom: 2px solid #d45895;
// }

.LineUpsTable .ant-table-thead > tr > th {
  color: #d45895;
  font-weight: 600;
  font-size: 16px;
}
.LineUpsTable .ant-table-thead {
  background: none;
  // border-bottom: 2px solid #D45895 !important;
}
.ant-table-empty .ant-table-body {
  overflow: visible !important;
}
// Recruiter Modal
.add-recruiter-modal .ant-modal-content,
.add-event-modal .ant-modal-content {
  border-radius: 10px;
}
// Search Nav
.search-slt {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #55595c;
  padding: 20px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}
.search-btn {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
  background: $theme_gradient;
  height: 40px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  border: none;
  font-weight: 600;
}
.search-box {
  // border: 1px solid red;
  // width: 700px;
  height: 12px;
  max-width: none;
}
.search-box input {
  width: auto;
  border: 1px solid #9a9a9a;
}
// .search-box.list-inline-item {
//   margin-left: 120px;
// }

.searchpage .profile-card-v2 {
  text-align: left;
}

.jobsearchfilter .ant-select {
  display: block;
}
.jobsearchfilter {
  border-right: 1px solid #d9d9d9;
  padding-right: 10px;
}
.candidate-details img {
  height: 100px;
  width: 100px;
}

.jobsearchfilter .ant-select-selection--single {
  height: 36px;
  padding: 3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-select-selection__rendered {
  margin-left: 4px;
  font-size: 13px;
}
// .ant-table-thead > tr > th .ant-table-header-column {
//   text-align: center;
// }
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
}
.list-inline-item .ant-input-group.ant-input-group-compact > *:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 40px;
}
.list-inline-item .ant-input-group.ant-input-group-compact > *:last-child {
  border-radius: 50px;
  z-index: 1;
}
.list-inline-item .ant-select-selection--single {
  padding: 0px 0px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
}
.list-inline-item .ant-btn {
  color: #fff;
  font-size: 15px;
 // background: $theme_button_color;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  width: 120px;
  margin-left: -45px;
  border-radius: 50px;
  height: 42px;
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
  height: 40px;
  padding-right: 25px;
}

.filterresultwrapper img {
  height: 75px;
  width: 75px;
}

// -----------------
@media screen and (min-width: 992px) {
  .form-card .form-card__img--left {
    border-radius: 0;
  }
}
// .form-card img {
//   height: 40px;
//   width: 150px;
//   margin: 15px;
// }

.form-card .form-card__img {
  border-radius: 0;
}

.form-card img {
  height: 50px;
  width: 200px;
  margin: 20px;
}

// .events .slick-slide{
//   height: 200px;
//   background: indianred;
// }

// .ant-carousel .slick-list .slick-slide.slick-active {
//   pointer-events: auto;
//   height: 300px;
//   padding: 10px;
//   margin: 20px;
//   background: indianred;
// }

.events {
  margin: 0 40px;
}
.events .slick-prev {
  left: -45px;
}

.events .slick-list div {
  width: 100%;
  display: inline-block;
  background: bisque;
  height: 300px;
}

.events .slick-prev:before,
.events .slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: red;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Event Details Page
.company__logo__eventdetails {
  margin: auto 0;
  img {
    // height: 180px;
    width: 90% !important;
  }
}
.company__logo img {
  height: 120px;
  padding: 20px;
  // width: 80%;
  // border: 1px solid rgba(112, 112, 112, 0.5);
}
.ant-table-tbody > tr > td:not(:first-child) {
  text-align: center;
}
.company__logo {
  margin: auto 0;
}
.company__enroll__icon {
  margin: auto 0;
  text-align: center;
}
.paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// .event__list {
//   max-height: 650px;
//   overflow-y: auto;
//   overflow-x: hidden;
//   border-bottom: 1px solid rgba(112, 112, 112, 0.2);
//   padding-bottom: 10px;
// }
// .company__event__details div {
//   padding-right: 0 !important;
//   padding-left: 0 !important;
//   h5 {
//     color: #d45895;
//     font-size: 17px;
//     padding-right: 10px;
//   }
//   p {
//     color: #707070;
//     font-size: 14px;
//   }
//   .dis-in {
//     display: flex;
//     text-align: left;
//   }
// }

.view-lineups-modal{
  .event-list{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border: $new-border;
margin-top: 15px;
padding: 15px;
border-radius: 16px;
  }
  .event-list__img {
    text-align: center;
    margin: auto;
    padding-top: 10px;
    img{
    height: 80px;
    width: 70%;
    }
  }
  .company__event__details{
    color: #4B426B;
    h4{
font: normal normal 600 19px/32px Poppins;

    }
    h5{
      display: inline;
      font: normal normal 600 15px/26px Poppins;
    }
    p{
      display: inline;
      font: normal normal 300 13px/26px Poppins;
    }
    .desc-para{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
      display: inline;
    }
  }
  .view-all{
    height: 650px ;
    overflow-y: scroll ;
  }
  
}

.subscription-modal {
  hr {
    border: 1px solid #4B426B;
    margin-top: 3px;
  }
  .invisible-radio {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }

  .invisible-radio:checked + label .styled-radio::after {
    content: "";
    font-size: 3em;
    display: inline-block;
    box-sizing: border-box;
    // padding: 20px;
    // width: 150px;
    text-align: center;
  }

  .invisible-radio:not(:checked) + label {
    cursor: pointer;
  }

  .invisible-radio:focus + label .styled-radio {
    transform: scale(1, 1);
    border: 3px solid #24ba1f;
    border-radius: 20px;
  }
  .ant-radio-inner::after {
    position: absolute;
    top: 6px;
    left: 6px;
    display: table;
    width: 9px;
    height: 9px;
    background-color: #d80075;
    border-top: 0;
    border-left: 0;
    border-radius: 0px;
  }
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border-radius: 0px;
  }
  .ant-radio-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
  }
  .ant-radio-button-wrapper {
    margin-right: 10px;
    height: 120px;
    width: 120px;
    text-align: center;
    justify-items: inherit;
    box-shadow: 0px 0px 10px #00000015;
    border: none;
  }
  .payment__box__wrapper {
    padding: 10px;
    // margin: 10px;
  }
  .payment__box {
    box-shadow: 0px 0px 10px #00000015;
    border-radius: 20px;
    width: 250px;
    height: 200px;
    // padding: 10px;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;
   text-align: center;
    h4 {
      font: normal normal 600 20px/20px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin: 0px;
      background: $new-theme_gradient;

border-radius: 16px 16px 0px 0px;
box-shadow: 0px 3px 6px #00000029;
height: 35px;
display: flex;
align-items: center;
justify-content: center;
    }
    p {
      font: normal normal 600 14px/20px Poppins;
color: #4B426B;
margin-top: 25px;
    }
    .ant-btn,.btn{
      margin-top: 10px;
      background: $new-theme_gradient;
box-shadow: 0px 3px 6px #00000029;
border-radius: 12px;
width: 80px;
height: 35px;
border: none;
color: #FFFFFF;
  font: normal normal 600 15px/20px Poppins;
&:hover{
  border: none;
}
    }
  }
  // new subscription style

  .subscription-modal__left{
    border-right: 1px solid rgba($color: #4B426B, $alpha: 0.3);
    color: #4B426B;
    .subscription-modal__left-title{
      margin: 0px;
      font: normal normal 600 20px/20px Poppins;

    }
    .subscription-modal__left-head{
      font: normal normal 600 14px/20px Poppins;
      margin: 0px;
    }
    .subscription-modal__left-para{
      font: normal normal 300 13px/20px Poppins;
    }
  }
  
  .subscription-tab{
   
     .ant-tabs-nav .ant-tabs-tab {
      font: normal normal 300 15px/20px Poppins;
      color: #4B426B;
      display: inline-block;
  }
  .ant-tabs-nav .ant-tabs-tab-active{
    font: normal normal 600 15px/20px Poppins;
color: #4B426B;
border-bottom: 2px solid #4B426B;
padding-bottom: 3px;
  }
  }
}

// KIND OF TODO::
// 1080 - 1600
// .ant-modal {
//   width: 85%;
// }

// table
// .ant-table-placeholder{
//   min-height: 600px;
// }

// Trainer Profile`

// Company Profile
.trainer-profile .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  // margin-right: 16px;
  white-space: nowrap;
  width: 50%;
}

// Spinner For Full Page
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.pd-10 {
  padding: 20px;
  margin-top: 20px;
}

.ant-upload-list-item-name {
  width: 450px;
}
// COOKIE
.cookie__container {
  // width: 100%;
  text-align: center;
  a:not(.btn) {
    color: palevioletred !important;
    text-decoration: underline;
  }
}
.job-list-download-icon{
  padding-left: 95%;
}

.app-sidenav{
  .sidenav-content{
    .sidenav-section{
      margin-bottom: 10px;
      
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
        .sidenav-icon{
          font-size: 20px;
          color: #FFFFFF;
          
        }
        &:hover {
          background: #694B94 0% 0% no-repeat padding-box;
        }
      
    }
    // .sidenav-active{
    //   background: #694B94 0% 0% no-repeat padding-box;
    //   border-left: 2px solid #FFFFFF;
    // }
    
  }
}

// dashboard 

.profile-card{
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 8px 15px #00000029;
border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
border-radius: 16px;
height: 120px;
margin-top: 10px;
.profile-card__left{
  height: 120px;
  justify-content: center;
  h3{
font: normal normal 600 20px/30px Poppins;
letter-spacing: 0px;
color: #4B426B;
  }
  p{
font: normal normal 300 20px/20px Poppins;
color: #4B426B;
  }
}
.profile-card__right{
  height: 120px;
  align-items: center;
  justify-content: center;
  .profile-card__right-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 8px 15px #00000029;
border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
border-radius: 50%;
height: 95px;
width: 95px;
margin-left: 15px;
cursor: pointer;
display: flex;
flex-direction: column;
justify-content: center;
h6{
  margin: 0px;
  font: normal normal 600 30px/30px Poppins;
letter-spacing: 0px;
color: #4B426B;
}
span{
  text-align: center;
font: normal normal 300 15px/20px Poppins;
letter-spacing: 0px;
color: #4B426B;
}
  }
}

}
.job-event__title{
  font: normal normal 600 17px/25px Poppins;
letter-spacing: 0px;
color: #4B426B;
margin: 10px 0px 5px 25px;
span{
  cursor: pointer;
}

}
.job-event__container{
  box-shadow: 0px 12px 15px #00000029;
  border: 0.30000001192092896px solid rgba($color:#694B94, $alpha: 0.3);
border-radius: 16px;
min-height: 800px;

  .ant-tabs-bar{
    margin: 0px;
    .ant-tabs-nav-scroll {
      background: $new-theme_gradient;
// box-shadow: 0px 3px 6px #00000029;
border-radius: 16px 16px 0px 0px;
    }
    .ant-tabs-nav .ant-tabs-tab{
      margin: 0px 0px 0px 3px;
      color: #FFFFFF;
      font: normal normal 600 18px Poppins;
    }
    .ant-tabs-nav{
      padding-left: 10px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      border-bottom: 2px solid #FFFFFF;
      padding-bottom: 0px;
    }
  }
  .ant-table-thead{
    background: $new-theme_gradient;
  }
   .ant-table-placeholder{
    border-radius: 0 0 20px 20px;
    border: none;
  }
  .ant-table-thead > tr > th .ant-table-header-column{
    font: normal normal 600 13px/20px Poppins;
color: #FFFFFF;
  }
  .ant-table-wrapper{
    .ant-table-row{
      cursor:pointer;
    }
  } 
  }
/******************** mobile ui ******************/
@media only screen and (max-width: 550px) {
  /*recruiter grid*/
  .recruiter-jobs
  {
    width: 109%;
    margin-left: -14px;
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td
    {
      padding: 0px;
    }
    .ant-table {
      font: normal normal 600 10px/15px Poppins;
    }
   tbody td {
      font: normal normal 300 10px/13px Poppins !important;
      color: #4B426B;
      }
      td
        .hyperlink{
          font: normal normal 600 10px/15px Poppins !important;
        }
        .recruiter-jobs__btn .ant-btn{
          border-radius: 11px;
         width: 111px;
        }
        .anticon svg{
          font-size: 9px !important;
        }
  }
  .add-view-recruiter-modal{
    width:100% !important;
  }
  .add-recruiter-modal{
    width:100% !important;
    
  }
  .ant-col-10 {
    width: 48.666667%;
  }
  /***** sort ******/
  .ant-table-thead > tr > th .ant-table-column-sorter{
    vertical-align: middle;
  }
  .view-shortlist-company-modal 
  {
    .ant-modal-close-x
    {
      width: 20px;
      height: 20px;
      line-height: 13px;
    }
    .ant-modal-content{
      width: 100%;
      margin-left: -0.5px;
    }
    .ant-modal-body
    {
       padding:0px;
    }
    .view-shortlist-company__details
    {
      padding-top: 1rem !important;
      .company-info-modal 
        {
          display: flex;
          .comapny-logo
          {
            margin-right: 12px;
            margin-left: 20px;
          }
          .company-info-modal__company-name {
            font: normal normal 600 15px/18px Poppins;
          }
        }
    }
    // .job-table_btn{
    //   .ant-btn{
    //     border-radius: 11px;
    //     width: 76px;
    //     height: 33px;
    //   }
    // }
    /*job item search*/
    .search-bar{
      margin-top: 9%;
    }
    .Job-Item{
        width: 108%;
        margin-left: -4%;
        height:142px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 12px 15px #00000029;
        border: 0.3px solid rgba(105, 75, 148, 0.3);
        border-radius: 16px;
        margin-bottom: 10px;
      .JobItem__img-mobile img{
        margin-right: 20px;
        margin-left: 20px;
        width: 90px;
        height: 50px;
        margin-top: 39px;
        margin-bottom: 50px;
    
      }
    }
   
    .JobItem-content
    {
      margin-left: 0px;
      margin-right: 0px;
      display: flex;
      
      p {
        font: normal normal 300 10px/11px Poppins !important;
        color: #4B426B !important;
       
        overflow: hidden
      }
      h5{
      font: normal normal 500 10px/11px Poppins;
      color: #4B426B !important;
      }
      .mr-2{
        margin-top: 6px;
        margin-left: -0.5rem;
      }
    }
  }
  .job-search{
  .JobItem_details{
    .job_title{
      margin-top: 10px;
      font: normal normal 500 15px/15px Poppins !important;
      color:#4B426B;
      margin-bottom: 10px;
      width:100px;
    }
    .company-name{
      font: normal normal 300 12px/13px Poppins !important;
      color:#4B426B;
    }
  }
  .blog-card__content{
    font: normal normal 300 15px/15px Poppins;
  }
  .search-bar .ant-input{
    border-radius: 15px;
    height: 38px;
  }
 
}
  .job-list__title{
    width:100%;
  }
  .job-table
  {
    .job-table-icons
    {
      margin-right: 2px !important;
      font-size: 10px !important;
    }
    .job-table-icons-v1
    {
      margin-right: 2px !important;
      font-size: 10px !important;
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td
    {
      padding:0px;
    }
    .ant-modal {
      max-width: calc(100vw);
      margin: 8px auto;
      }
    
     .ant-table-thead > tr > th .ant-table-header-column {
      font: normal normal 600 8px/9px Poppins;
      color: #FFFFFF;
      line-height: 10px;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    /* padding: 16px 16px; */
    overflow-wrap: break-word;
    }
    .ant-input, .ant-input-number, .ant-select-selection--single, .ant-select-selection--multiple {
      height: 16px;
      padding: 2px 2px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
  }
  }
  .view-shortlist-company-modal{
    .job-table__title  {
      h3{
        font: normal normal 300 12px/25px Poppins;
        color: #FFFFFF;
      }
    }
    .anticon 
    {
      svg 
      {
        font-size: 9px;
      }
    }
    #Partner_Jobsearch
    {
      svg 
      {
        font-size: 20px;
      }
    }
    tbody 
    {
      .ant-table-tbody > tr > td {
        line-height: 5px;
      }
      td{
        font: normal normal 300 8px/14px Poppins;
        color: #4B426B;
      }
      
      .ant-select-selection--single 
      {
        .ant-select-selection-selected-value
        {
          font: normal normal 300 7px/10px Poppins;
        }
      }
      td:nth-of-type(1) {
        font: normal normal 600 8px/11px Poppins;
      }
    }
    }
  
    .profile-section
    {
      height: 236px;
      padding-left: 41px;
      .profile-card {
        height:61px;
        margin-left: -27px;
        width: 121%;
        border-radius: 12px;
        margin-top:1px;
       // border: 0.30000001192092896px solid #694B94;
      .profile-card__left{
        height:61px;
        h3{
          font: normal normal 600 15px/23px Poppins;
        }
        p{
          font: normal normal 300 15px/23px Poppins;
        }
      }
      .profile-card__right {
        height:92px;
      .profile-card__right-card
      {
        height: 76px;
        width: 84px;
        h6{
          font: normal normal 600 18px/27px Poppins;
        }
        span{
          font: normal normal 300 12px/14px Poppins;
        }
      }
    }
    }
    }
  .form-card img {
    height: 19px;
    width: 113px;
    margin: 11px;
  }
  .form-card-page{
    .p-5 {
      padding: 1rem !important;
  }
  } 
  .app-header-inner{
  .header-left{
  .header_logo{
      padding:20px 20px 20px 9px !important;
    }
  }
}
.form-card__body {
.form-v1-container{
.ant-form-item-control .ant-form-item-children 
 .ant-input-affix-wrapper-lg
{
 width:100%;
}
#Partner_User_Login
{
  width:85%;
}
}
.form-card__login {
  width:100% !important;
}
}
.add-quires{
  .ant-modal-content{
    width: 239%;
    margin-left:-99px;
  }
  .ant-modal-title{
    font: normal normal 600 15px/23px Poppins !important;
  }
  .ant-btn-primary{
    border-radius: 6px;
  }
}
#Partner_forgotpwd_SendResetInstructions{
  background:linear-gradient( 
        90deg
    , #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    border: transparent;
}
.para-icon{
    margin-right:25px;
    margin-top: -20px;
}
/***********sidenav************/
.app-sidenav-container{
  .app-sidenav{
    height:151px;
  }
}
.fixed-header .ant-layout{
  z-index:0 !important;
}
/******sidenav end ******/
.recruiter-mobile{
  .recruiter-jobs__btn{
    .ant-btn{
      color:#4B426B;
      font: normal normal 600 12px/18px Poppins;
      width: 115px;
      border-radius:11px;
    }
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    font: normal normal 600 9px/10px Poppins;
    line-height: 12px;
  }
  .anticon svg{
    margin-left: -7px;
  }
}
.ant-modal-centered{
  .ant-modal-content{
   width:199%;
   margin-left:-89px;
  }
}
  .resume-align{
  border:1px solid #4B426B !important;
  border-radius:6px !important;
  width:250px;
  height:144px;
}
.ant-select-dropdown{
  font-size:10px;
}
/********credits**********/
.flex-bank-page{
  .credit-account{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-6 {
    flex: 0 0 120%;
    max-width: 120%;
    margin-left: 9%;
  }
}
/******credits end *****/
/*********edit profile ********/
.edit-profile{
  .ant-modal-content{
    width: 100%;
   // margin-left: -31%;
  }
}
.job-event__container {
  .ant-tabs-bar .ant-tabs-nav {
  .ant-tabs-tab-active .ant-tabs-tab{
  font: normal normal 600 15px/23px Poppins;
  }
  .ant-tabs-tab{
    font: normal normal 600 15px/23px Poppins;
  }
}
.ant-table-thead > tr > th .ant-table-header-column {
  .ant-table-column-title{
  font: normal normal 600 7.5px/23px Poppins;
  }
}
tbody{
  .hyperlink{
    font: normal normal 600 08px/12px Poppins;
  }
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td
{
  font: normal normal 300 10px/12px Poppins;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 0px 0px;
 }
.ant-table-thead > tr > th .ant-table-header-column
{
  font: normal normal 600 09px/12px Poppins;
}
.ant-table-row-cell-ellipsis, .ant-table-row-cell-ellipsis .ant-table-column-title
{
  white-space: break-spaces;
}
.jobdesc_table{
  margin-top: 10px;
  text-align: left;
  white-space: break-spaces;
  h2{
    font-size: 10px;
  }
  li{
    list-style-type: none;
    margin-left: -38px;
  }
  p{
    font: normal normal 300 10px/14px Poppins !important;
    margin-left: 5px;
  }
}
tbody .hyperlink {
  font: normal normal 600 09px/12px Poppins;
}
.anticon svg{
  font-size: 10px;
}
}
.account-details{
  width: 66%;
  height: 191px;
  margin-bottom: 23px;
}
.ant-select-dropdown-menu-item{
  padding: 1px 5px;
  font-size: 9px;
}
.assigned-jobs{
  thead th {
    padding: 8px 8px 0 8px !important;
}
}
.container-fluid{
  .job-event__title{
    font: normal normal 600 15px/23px Poppins;
  }
  .job-event-mobile-padding
  {
    padding-left: 0px;
    padding-right: 0px;
    .job-event__container{
      min-height: 670px;
    }
  }
  .JobStatsWrapper
  {
    border-radius: 0px;
    text-align:left;
    .swiper-wrapper
    {
      margin-bottom: 12px;
    }
    .mobile-viewall
    {
      margin-top: -10px !important;
    }
    .job-list-item
    {
      border: 0px;
    }
  }
  }

  .dashboard-right__container
  {
    border: 0px;
    min-height: 570px;
    .mobile-padding
    {
      margin-top: 0rem !important;
    }
  }
  
  .notify-me {
    height:34px;
  .ant-badge{
    .notify-mobile{
      width:26px;
      height:25px;
      margin-right:-14px;
    }
  }
  }
  .ant-avatar > img{
    width: 66px;
    height: 65px;
    margin-top: 4px;
    margin-left: 11px;
  }
  .job_status{
    .ant-select-enabled{
      width:94% !important;
      margin-top:10px;
    }
    .ant-select-selection__rendered{
      .ant-select-selection-selected-value{
        max-width: 190%;
      }
    }
    .ant-select-arrow-icon{
      margin-right: -9px;
    }
  }
  .company-info-details{
    h5{
      font: normal normal 600 15px/23px Poppins;
      color: #4B426B;
    }
  }
  .company-job__btn{
    margin-right: 55%;
     .ant-btn {
       width:176px;
       height:40px;
       span{
         white-space: normal;
         font: normal normal 600 12px/16px Poppins;
       }
     }
  }
  .assign-event-modal{
    .ant-modal-content{
      width: 198%;
      margin-left: -49%;
    }
    .ant-modal-body {
      padding:13px;
    }
    .assign-event-modal__details{
      .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 0px 0px; 
        overflow-wrap: anywhere;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
      font: normal normal 600 10px/14px Poppins;
    }
    tbody td:nth-of-type(1) {
      font: normal normal 600 10px/17px Poppins;
  }
  }
}
.ant-modal{
  max-width: calc(100vw);
}
.enrolled-event {
.ant-table-thead > tr > th .ant-table-header-column {
  .ant-table-column-title{
  font: normal normal 600 8.5px/30px Poppins;
  }
}
.shortList-filter__btn .ant-btn{
    border-radius: 11px !important;
    width: 70px !important;
    font: normal normal 600 12px/18px Poppins;
    color: #4B426B;
}
.enrolled-event__title h3{
  font: normal normal 600 15px/23px Poppins !important;
}
}
  // .job-table_btn{
  //   .ant-btn > .anticon + span{
  //     margin-left:1px;
  //   }
  // }

  .job-table_btn{
    .job_filter,.job_upload{
      top:-36px;
    }
    .ant-btn > .anticon + span{
      margin-left:1px;
    }
    .ant-btn{
      width:78px !important;
      font: normal normal 600 12px/18px Poppins;
      color:#4B426B;
    }
  }
  .ant-table-layout-fixed{
    .ant-table-row-cell-break-word {
      word-break: normal;
    }
    thead th{
      padding:8px 2px 0 2px !important;
    }
  }
  .justify-content-between{
  .shortList-filter__btn .ant-btn {
    border-radius: 11px !important;
    width: 70px !important;
    font: normal normal 600 12px/18px Poppins;
    color:#4B426B;
  }
}
.assigned-jobs{
  .shortList-filter__btn .ant-btn {
    border-radius: 11px !important;
    width: 70px !important;
    font: normal normal 600 12px/18px Poppins;
    color:#4B426B;
  }
}
.ant-modal-centered .ant-modal-content {
  width: 257%;
  margin-left: -111px;
  border-radius: 30px;
  margin-top: 70%;
}
.job-detail-modal .job-detail-modal__down{
  flex-wrap: wrap-reverse;
  .job-detail-modal__down-right
   img{
    width:45% !important;
  }
.col-12{
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
}
.company_data{
  text-align: left;
  margin-left: 12px;
  margin-top:-8px;
  p{
    font: normal normal 300 15px/23px Poppins;
  }
}
.job-detail-modal__down-left{
  h4{
    font: normal normal 600 26px/28px Poppins;
    margin-top: 21px;
  }
}
}
.ant-modal-close{
  .ant-modal-close-x{
    width: 18px;
    height: 18px;
    font-size: 11px;
    line-height: 16px;
  }
}
  /***************subscription **************/
  .subscription-modal{
    width: 100% !important;
    max-width: calc(100vw - 2px);
    margin: 8px auto;
    .ant-modal-content
      {
        width: 100%;
      }
    .ant-modal-body{
      padding:0px;
      min-height: 600px;
      margin-top: 11px;
      
    }
    .mobile-subscriptioninfo
    {
      margin-left: 0;
    }
    .payment__box
    {
      width: 158px !important;
      margin-left:-1px !important;
    }
    .subscription-tab .ant-tabs-nav {
    .ant-tabs-tab-active{
      font: normal normal 600 11px/13px Poppins;
      text-align: center;
    }
    .ant-tabs-tab {
      font: normal normal 400 14px/15px Poppins;
      text-align: center;
    }
  }
  }
  .subscription_title_text{
  .subscription-modal__left-head{
    font: normal normal 600 14px/21px Poppins;
  }
  .subscription-modal__left-para{
    font: normal normal normal 14px/21px Poppins;
  }
}
.Partner_account_ProfileSubmissionCredits
{
  .account-section 
  {
    .account-section__submission tbody td:nth-of-type(1) {
      font: normal normal 600 9px/20px Poppins;
    }
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td 
  {
    padding: 0px 0px;
  }
}
.upload-modal
{
  .ant-modal-body
  {
    padding: 1px !important;
  }
  max-width: calc(100vw);
  .ant-modal-content
  {
    width: 94%;
  }
 .action-header
 {
   color: white;
   background: linear-gradient(
    90deg
    , #965C89 0%, #694B94 100%) 0% 0% no-repeat padding-box;
 }
 .action-block
 {
   border:1px solid black;
   margin-top: 14px;
   .ant-col-12 
   {
     width: 100%;
   }
   .ant-input-number
   {
    width: 100%;
   }
   .ant-form-item-label
   {
    padding: 0 0 0px;
    line-height: 0;
   }
   .ant-form-item
   {
     padding:10px;
   }
   .action-note
   {
    text-align: left;
    font-size: 11px;
    color: rgb(112, 112, 112);
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 0rem;
   }
   .ant-upload.ant-upload-drag {
    width:auto; 
    height: auto;

   }
   
   .action-header
   {
     display: flex;
     .action{
      width: 50%;
      text-align: left;
      padding-left: 10px;
     }
     .download
     {
      width: 50%;
      text-align: right;
      padding-right: 10px;
     }
     #Partner_candidatejob_downloadResumemobile
     {
       svg
       {
         color:white !important;
       }
     }
   }

 }
 .ant-col-12
 {
  width: 100%;
  text-align: left;
 }
}
.upcoming-event-modal{
  width:100% !important;
  .upcoming-event-modal__list-top{
    .text-center img{
      width: 50% !important;
      height: 82px !important;
    }
  }
}
.ant-form-item{
  .ant-form-item-label > label{
    color:#4B426B;
  }
}
/***********filter*************/
.filter-submission {
.submission-table .filters-wrapper {
.filter-wrapper__sub-title {
  margin: 20px 0 -15px 0 !important;
  font: normal normal 600 18px/27px Poppins;
}
.ant-btn-primary{
  border-radius: 6px;
  opacity: 1;
  width: 49%;
  height: 40px;
}
}
.filters-wrapper__right .filters-wrapper__right--title h3{
  font: normal normal 300 10px/15px Poppins !important;
}
}
.filters-wrapper__right{
.filters-wrapper__right-downloadbtn{
  width:20px !important;
  border:none !important;
  border-radius:0 !important;
  box-shadow: none !important;

}
.filter-submission
{
  max-width: calc(100vw);
  .ant-modal-body
  {
    padding: 15px;
  }
 
}
.filters-wrapper__right-container
{
  .ant-table-thead > tr > th
  {
     padding: 0px 0px  !important; 
    overflow-wrap: break-word;
}
.ant-table-tbody > tr > td {
  padding: 0px 0px !important; 
 overflow-wrap: break-word;
}
}
}
.filter-btn{
  .ant-btn{
  color:#4B426B;
  font: normal normal 600 14px/18px Poppins;
  } 
}
/****************footer***************/
.footer-mobile {
  height: 127px;
  background: #C4C2C2 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
.footer-links 
{
  width: 100%;
  li {
  list-style: none;
  color: #66439a;
  font: normal normal normal 14px/18px Helvetica;
 // padding: 5px 10px;
  // font-weight: 600;
  a{
    color:#4B426B;
  }
}
div:nth-child(2)
  {
    width:63%;
  }  
}
.footer-social-list li:not(:last-child) {
 margin-right: 0.5rem; 
}
.footer-social-list a
{
opacity: inherit;
font-size: 25px;
}
.data{
font: normal normal normal 8px/10px Helvetica;
}
.footer-logoo{
// margin-top:22px;
margin-top: -14px;
}
.footer-box{
margin-top:12px;
}
}
.mobile-account-section
{
  .ant-tabs-tab
  {
    width: 31%;
    white-space:normal;
  }
  .ant-tabs-nav
  {
    .ant-tabs-tab
    {
      margin: 0 6px 0 0;
      padding: 6px 3px;
      .mobile-account-tab
      {
        position: absolute;
        top: -11px;
      }
    }
  } 
}
/*************footerend *****************/
/************upcomingevents**************/
.view-lineups-modal{
  width: 100% !important;
  max-width:calc(100vw - 0px);

  .container {
    padding-right: 5px;
    padding-left: 5px;
}
.company__event__details{
 h4{
  font: normal normal 600 13px/15px Poppins !important;
}
h5{
  font: normal normal 600 10px/16px Poppins;
}
p{
  font: normal normal 300 10px/16px Poppins;
}
}
.event-list__img
{
  padding-top: 10px;
  margin-right: 3px;
  width: 20%;
  margin-left: 3px;
    img {
      height: 44px;
      width: 46%;
  }
}
.company__event__details
{
  width: 78%;
  .event_detail{
    line-height: 0;
    display:flex;
    .row{
      margin-left: 1px;
      margin-right:1px;
    }
    .pt-2
    {
      padding-top: 0rem !important;
    }
    .col-sm-4
    {
      padding-left: 0px;
    }
  }
  
}
.col-md-2,.col-md-8,.col-md-4,.col-sm-8{
  padding-right: 0px;
  padding-left: 0px;
}
.ant-modal-body
{
  padding: 0px;
}
.event-list{
  p{
    font: normal normal 600 10px/12px Poppins;
    color:#4B426B;
  }
  margin-top:42px;
  height: 160px;
}
}
.company__enroll__icon{
  .event_enroll{
    float:right;
    margin-right: 20px;
  }
}
.no_event{
  text-align: center;
}
.no_job_found{
  text-align: center;
}
.justify-content-between{
  .job-container-card__lastJob{
    padding: 5px 0px 0px 27px !important;
  }
}
.job-container-card__details{
  .event-title{
    height: 58px;
    margin-top: 19px;
  }
}
}
.subscription-modal
.payment__box
{ 
  @media only screen and (min-width:393px){
  width:175px !important;
  }
  @media only screen and (min-width:412px){
    width:185px !important;
  }
}
/*******************************mobile ui end *********/
.ant-table-row-cell-ellipsis, .ant-table-row-cell-ellipsis .ant-table-column-title
{
  white-space: break-spaces;
  overflow: visible;
  text-align: center;
}
.app-header .ant-layout-header{
  height: 30px;
}

.jobdesc_table{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-table-pagination.ant-pagination{
  float: none;
  text-align: center;
}
.ant-pagination-item{
  min-width: 27px;
  height: 27px;
  line-height: 27px;
  border-color: #4B426B;
  a{
    color: #4B426B;
  }
  
}
.ant-pagination-item-active{
  background-color: #4B426B;
 a{
   color: #FFFFFF;
 }
}
.ant-pagination-disabled a{
  border: none;
  min-width: 27px;
  height: 27px;
  line-height: 35px;
  
}
// .anticon svg  {
//   font-size: 16px;
// }
.ant-pagination-prev .ant-pagination-item-link,.ant-pagination-next .ant-pagination-item-link {
  border: none;
  color: #694B94;
}
.ant-modal-content {
  border-radius: 0;
  width: 100%;
  margin: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border: 0.30000001192092896px solid rgba($color: #4B426B, $alpha: 0.3);
border-radius: 16px;
opacity: 1;
}
.ant-modal-close {
  margin: 4px;
}

// filter Components

.filter-form{
  .form-v1{
    .ant-form-item-label {
      line-height: 30px;
      label{
        font: normal normal 600 15px/20px Poppins;
letter-spacing: 0px;
color: #4B426B;
      }
      
    }
    .ant-select-selection--single,.ant-input {
      border: 1px solid #4B426B;
border-radius: 8px;
  

      
    }
    .ant-form-item-control span  {
  
      svg{
        color: #4B426B 
     
      }
    
  }
  .ant-form-item {
    margin-bottom: 5px;
}
.ant-btn-primary {
  background:$new-theme_gradient;
box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;
border: none;
width: 100px;
}
  }
}

.add-quires{
  .ant-modal-header{
    border: none;
    padding: 16px 0px 0px 24px;
  }
  .ant-modal-title {
    font: normal normal 600 18px/20px Poppins;
color: #4B426B;
  }
  .ant-modal-body {
    padding: 10px;
  }
  .ant-avatar {
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #760FAC29;
color: #4B426B;
font: normal normal 600 18px/44px Poppins;
  }
  textarea.ant-input{
    border: 1px solid #4B426B;
border-radius: 12px;
opacity: 1;
  }
  .ant-btn-primary {
    background: $new-theme_gradient;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
border: none;
  }
}



// Bulk resume upload
.bulk-upload{
  .ant-modal-body{
  min-height: 400px;
  padding: 35px 24px 24px 24px;
  .ant-steps{
    margin-bottom: 25px;
    .ant-steps-item-process .ant-steps-item-icon{
      background-color: #4B426B;
      border-color: #4B426B;
    }
    .ant-steps-item-title{
      color: #4B426B;
    }
    .ant-steps-item-wait .ant-steps-item-icon,.ant-steps-item-finish .ant-steps-item-icon{
      border-color: #4B426B;
      span{
        color: #4B426B;
      }
    }
  }
  .resume-btn{
     border-radius: 12px;
    height: 65px;
    margin: 4px 8px;
    text-align: center;
    padding:5px;
    width:128px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 15px #00000029;
    border: 0.30000001192092896px solid #694B94;
    font: normal normal 600 15px/20px Poppins;
    color: rgba($color: #4B426B, $alpha: 0.4);
    border-color: rgba($color: #4B426B, $alpha: 0.4);
    .resume-btn__icon{
      margin-top: 5px;
      font-size: 22px;
      color: #4B426B;
    }
  }
  .ant-btn-primary{
    background: $new-theme_gradient;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
border: none;
width: 110px;
height: 35px;
margin-bottom: 15px;
  }
  .note-title{
    width: 18%;
    margin: auto;
    p{
font: normal normal 500 12px/15px Poppins;
color: #4B426B;
    }
    span{
      font-weight: 600;
    }
  }
  }
 
  thead{
    border-radius: 16px;
    th{
      padding: 10px 16px;
    }
  }
  .list-upload-user{

border: $new-border;
border-bottom: none;
border-radius: 16px;
box-shadow: 0px 12px 15px #00000029;

  }

  .ant-table-placeholder{
    border:none;
    border-radius: 0 0 16px 16px;
  }
  .final-step{
    padding: 5px 0 0px 20px;
     
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        .ant-btn{
          margin: 10px 10px 5px 0
          
        }
  }
  .list-upload-user__top{
    background: $new-theme_gradient;
    height: 10px;
    border-radius: 16px 16px 0 0;
  }

}
.ant-popover-inner{
  box-shadow: 0px 12px 15px #00000029;
    border-radius: 16px;
  .ant-popover-inner-content{
    
    .ant-popover-message{
      color: #4B426B;
    }
    .ant-btn-sm{
      border-color: #4B426B;
      color: #fff;
      border-radius: 12px;
      height: 25px;
    
    }
    .ant-btn-primary{
      background: $new-theme_gradient;
      color: #FFFFFF;
      border: none;
      width: 70px;
    }
  }
}
.form-card__login {
  border: $new-border;
  .form-v1-container{
    margin-top: 40px !important;
  }
}

.assigned-shortlist__request-btn{
  .ant-modal-header {
    padding: 14px 6px;
    color: #4B426B;
    border-bottom: $new-border;
    border-radius: 16px 16px 0 0;
    .ant-modal-title{
      font: normal normal 600 14px/20px Poppins;
      color: #4B426B;
      width: 90%;
    }
  
}
.ant-modal-footer {
  padding: 6px 16px;
  text-align: center;
  border-top: $new-border;
  border-radius: 0 0 16px 16px;
  .ant-btn {
    background: $new-theme_gradient;
    color: #FFFFFF;
    border-radius: 16px;
    width: 100px;
    height: 30px;
  }
}
}

/***************** Table section *********/
thead{
  th{
    padding: 10px 10px 0 10px !important;
  }
}
.ant-table-thead > tr > th .ant-table-header-column {
  font: normal normal 600 13px/20px Poppins;
  color: #FFFFFF;
  line-height: 16px;
 }
 .ant-table-tbody > tr > td{
   line-height: 15px;
 }
 tbody{
  tr:nth-child(odd){
    background:  rgba($color: #4B426B, $alpha: 0.2) 0% 0% no-repeat padding-box !important;
    
  }
  td{
    font: normal normal 300 12px/14px Poppins;
    color: #4B426B;
  }
  td:nth-of-type(1){
    font: normal normal 600 14px/20px Poppins;
  } 
  .ant-select-selection--single{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border: 0.30000001192092896px solid #694B94;
border-radius: 19px;
.ant-select-selection-selected-value{
  color: #694B94;
  font: normal normal 300 12px/27px Poppins;
}
.ant-select-arrow .ant-select-arrow-icon {
  color: #694B94;
}
  }
 
 }
 /***************** End Table section *********/


 .shortList-filter__btn{
  .ant-btn{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    // box-shadow: 0px 12px 15px #00000029 !important;
    border: 0.30000001192092896px solid #694B94 !important;
    border-radius: 19px !important;
    width: 120px !important;
  }

}

.enrolled-event{
  .enrolled-event__title{
    background: $new-theme_gradient;
    border-radius: 16px 16px 0px 0px;
    padding: 10px;
    h3{
      margin: 0px 0px 0px 3px;
      color: #FFFFFF;
      font: normal normal 600 18px Poppins;
  }
  

  }
}

.view-enrolled-event{
  .view-enrolled-event__event-detail{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #00000029;
border:$new-border;
border-radius: 16px;
color: #4B426B;
.company-info-modal{
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
}
h4{
  font: normal normal 500 13px/20px Poppins;
}
    h5{
      margin: 0px;
      font: normal normal 600 13px/15px Poppins;
    }
    p{
      margin: 0px;
      padding-bottom: 5px;
      font: normal normal 300 13px/26px Poppins;
    }
   
  }
}
.recruiter-table-wrapper{
  box-shadow: 0px 12px 15px #00000029;
  border: 0.3px solid rgba(105, 75, 148, 0.3);
  border-radius: 16px;
  .recruiter-table-wrapper__details{
    background: $new-theme_gradient;
          border-radius: 16px 16px 0px 0px;
          padding: 10px;
          h3{
            font: normal normal 300 20px/25px Poppins;
            color: #FFFFFF;
          }
  }
}

.assign-event-modal{
  .assign-event-modal__details{
    box-shadow: 0px 12px 15px #00000029;
    border: 0.3px solid rgba(105, 75, 148, 0.3);
    border-radius: 16px;
    h4{
      margin: 0px;
      background: $new-theme_gradient;
      border-radius: 16px 16px 0px 0px;
      padding: 10px;
      font: normal normal 300 18px/25px Poppins;
      color: #FFFFFF;
    }
  }
  
}
.ant-switch-checked{
  background-color: #4B426B;
}

.upcoming-event-modal{
  .upcoming-event-modal__list{
    color: #4B426B;
    .upcoming-event-modal__list-top{
      
      img{
        width: 100%;
        height: 50px;
        border-radius: 50%;
      }
      .upcoming-event-modal__list-title{
        display: flex;
        align-items: center;
        justify-content: center;
        h4{
          font: normal normal 600 17px/20px Poppins;
     
        }
      }
     
      p{
        font: normal normal 500 13px/20px Poppins;
      }
    }
    .upcoming-event-modal__list-bottom{
      h5{
        font: normal normal 600 14px/20px Poppins;
      }
      p{
        font: normal normal 300 14px/20px Poppins;
      }
    }
    hr{
      margin: 5px;
    }
  }
}
.add-recruiter-modal{
  .ant-upload.ant-upload-drag{
    border: 1px dashed #4B426B !important;
    }
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
      color: #4B426B !important;
    }
    .ant-input-number{
      border: 1px solid #4B426B !important;
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  .ant-btn{
    color: #FFFFFF;
  }
}

.jobdesc_table{
  h1,h2,h3,h4,h5,h6,p,span,li,div{
    font: normal normal 300 12px/14px Poppins !important;
  }
}
.disableclick{
  pointer-events: none;
 
}
.show-pointer
{
  cursor: pointer;
}
