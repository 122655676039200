@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
$new-primary-color:#4B426B;
.ant-layout-footer{
    // height: 50px;
    // position: relative;
    padding: 20px 0;
    background: #C4C2C2 0% 0% no-repeat padding-box !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .footer-link {
      text-decoration: none;
      font-size: 13px;
      text-transform: lowercase;
      color: $new-primary-color;
  
    }
    .footer-links li {
      list-style: none;
      color: #66439a;
      font-size: 14px;
      padding: 5px 10px;
      // font-weight: 600;
      
    }
    .footer-logo
    {
      width: 200px;
      margin-top: 11px;
    }
    .footer-box p{
      font-size: 15px;
      color: $new-primary-color;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
      
    }
    a {
      color: $new-primary-color;
      // font-size: 17px;
      text-decoration: none;
      text-transform: capitalize;
      font: normal normal 300 17px/25px poppins;
      margin-top: 10px;
    }
    .footer-social-list li{
      margin-right: 5px !important;
    }
    .footer-social-icons {
      font-size: 23px;
      opacity: 1;
    }
  }